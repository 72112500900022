import { styled } from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RefReady from '../ref'
import './SearchBussiness.css'
import { useEffect, useRef, useState } from 'react'
import ButtonRed from '../button/button'
import { CompleteThisBussiness } from '../../2. redux/actions/thisBussinessActions';
import { useDispatch } from 'react-redux';
import { GetAllBussinessToSearch, GetBussinessToSearch, GetOneBussiness } from '../../axios/api';
import BottomMenu from '../bottomMenu/BottomMenu';

// const ImageDiv = styled.div`
//         background-image: url(${props => props.$pic}); 
//     `;

const SearchBussiness = () => {

    var p = useParams()
    const d = useDispatch()
    var navigate = useNavigate()
    var name = p.name;
    const location = useLocation();

    const baseImageUrl = "https://kolsimcha.co.il/images";
    const didExios = useRef(false);

    const [arrBussiness, setArrBussiness] = useState([])
    useEffect(() => {
        const arr = location.state?.allFind || [];
        if (p.name === "כל העסקים" && !didExios.GetAllBussinessToSearch) {  // רק אם טרם התבצע
            didExios.GetAllBussinessToSearch = true;  // להימנע מביצוע נוסף
            GetAllBussinessToSearch(arr).then((data) => {
                setArrBussiness(data)
            }).catch((error) => { console.error(error) });
        }
        else if (p.name != "כל העסקים" && !didExios.GetBussinessToSearch) {  // רק אם טרם התבצע
            didExios.GetBussinessToSearch = true;  // להימנע מביצוע נוסף
            GetBussinessToSearch(arr).then((data) => {
                setArrBussiness(data)
            }).catch((error) => { console.error(error) });
        }
    }, [location.state?.allFind])

    const moveToBussiness = (b) => {
        GetOneBussiness(b.categoryCode, b.id).then((data) => {
            d(CompleteThisBussiness(data));
            navigate(`/bussiness/קול שמחה/${p.name === "כל העסקים" ? "כל העסקים" : "תוצאות חיפוש"}/${b.cc}`)
        }).catch((error) => { console.error(error) })
    }

    return <div>
        <p style={{ fontSize: '90%', fontWeight: '300', marginTop: '-6%', color: '#c00000', float: 'right', marginRight: '15%' }}> <span style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => navigate("/")}>קול שמחה &#8592;</span> <span style={{ display: 'inline-block', cursor: 'pointer' }}>{name} &#8592;</span> </p>

        <p id='titleHead' style={{ fontSize: '150%', fontWeight: '400', marginTop: '10%', textAlign: 'center', width: '100%', marginBottom: '2px' }}>{name} <span style={{ fontSize: '60%' }}>({arrBussiness.length})</span></p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        <div style={{ marginTop: '2%' }}>
            {arrBussiness.map((x, i) => (
                <div key={i} className='together' style={{ display: 'inline-block', margin: '3%', position: 'relative' }}>
                    <div className='buton text textSearch'
                        onClick={() => { moveToBussiness(x) }}>
                        <ButtonRed text={x.name}></ButtonRed>
                    </div>

                    <RefReady tag={
                        <span style={{ position: 'relative' }} className="image imageSearch">
                            {x.mainImage && <img style={{ height: '100%', width: '100%', objectFit: "cover", objectPosition: "center" }}
                                loading="lazy"
                                src={encodeURI(`${baseImageUrl}${x.mainImage?.replace(/\\/g, '\/')}?format=avif`)}
                                className="image2"
                                alt=""
                            />}
                            <div className='border2'></div>
                            <div className='border3'></div>
                            <div className='black'>פרטים נוספים</div>
                            {/* <ImageDiv loading="lazy" $pic={encodeURI(`${baseImageUrl}${x.mainImage?.replace(/\\/g, '\/')}?format=avif`)}
                            onClick={() => { moveToBussiness(x) }}
                            className='image imageSearch'>פרטים נוספים<div className='border2'></div>
                            <div className='border3'></div><div className='black blackSearch' style={{ lineHeight: '30vh', backgroundColor: 'rgba(0, 0, 0, 0.31)' }}>פרטים נוספים</div></ImageDiv> */}
                        </span>
                    } event={'animate__fadeInDown'} event2={'animate__faster'}>
                    </RefReady>
                </div>
            ))}
        </div>

        {arrBussiness.length === 0 && <div style={{ marginTop: '10%' }}></div>}
        <BottomMenu></BottomMenu>
    </div>
}

export default SearchBussiness